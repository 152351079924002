import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatSort, MatPaginator, DateAdapter } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserMaster } from '../AllModels';
import { addUserMasterURL, checkMobileNumberURL, getAllUserMasterURL, getByIdUserMasterURL, updateLineMasterURL, updateUserMasterURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';

@Component({
  selector: 'app-userregistration',
  templateUrl: './userregistration.component.html',
  styleUrls: ['./userregistration.component.css']
})
export class UserregistrationComponent implements OnInit {

  userregistrationForm: FormGroup;
  UserMasterModel = new UserMaster();
  isEdit: boolean = true;
  hide = true;
  mobileMsg: string;
  mobFlag: boolean = false;
  textColorMobile: string;

  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'User Name', 'Number', 'Password', 'Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  ngOnInit() {
    this.userregistrationForm = this.formBuilder.group({
      fullname: new FormControl('', [Validators.required,]),
      number: new FormControl('', [Validators.required,]),
      password: ['', [
        Validators.required,
        Validators.minLength(8),
      ]],
      status: new FormControl('', [Validators.required]),
    })
    this.getAllUser();
    this.hide = true;
  }


  SaveUser() {
    if (this.isEdit) {
      this.UserMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
      if (this.mobFlag == true) {
        this.httpService.postRequest(addUserMasterURL, this.UserMasterModel).subscribe((data: any) => {
          if (data) {
            this.isEdit = true;
            this.toastr.successToastr('Save Successfully...!', 'Success!', { timeout: 500 });
            this.getAllUser();
            this.userregistrationForm.reset();
            this.mobileMsg = " ";

          }
          else {
            this.toastr.errorToastr("Something went Wrong.", "Error");
          }
        })
      }
      else {
        this.toastr.errorToastr("Please Enter Valid Mobile Number.", "Error");
      }
    }
    else {
      this.UserMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
      this.httpService.putRequest(updateUserMasterURL, this.UserMasterModel).subscribe((data: any) => {
        if (data) {
          this.isEdit = true;
          this.toastr.successToastr('Update Successfully...!', 'Success!', { timeout: 500 });
          this.getAllUser();
          this.userregistrationForm.reset();
          this.mobileMsg = " ";
        }
        else {
          this.toastr.errorToastr("Something went Wrong.", "Error");
        }
      })

    }
  }

  getUserById(userId) {
    this.httpService.getRequest(getByIdUserMasterURL + "/" + userId).subscribe((data: any) => {
      this.UserMasterModel = data;
      this.isEdit = false;
      const element = document.querySelector('#scrollId');
      element.scrollIntoView();
    })
  }

  getAllUser() {
    this.httpService.getRequest(getAllUserMasterURL + "/" + sessionStorage.getItem("agentId")).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }

  checkMobileNumber(mobNo) {
    if (mobNo.length == 10) {
      this.httpService.getRequest(checkMobileNumberURL + "/" + mobNo).subscribe((data: any) => {
        if (data == true) {
          this.mobileMsg = "Valid Mobile No ";
          this.textColorMobile = "green";
          this.mobFlag = true;
        }
        else {
          this.mobileMsg = "Mobile Number Already Exist";
          this.textColorMobile = "Red";
          this.mobFlag = false;
        }
      })
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
